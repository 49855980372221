import React from "react";
import ReactDOM from "react-dom";
import axios from "axios";
import i18n from "i18next";
import { I18nextProvider, initReactI18next } from "react-i18next";
import HttpApi from "i18next-http-backend";
import LanguageDetector from "i18next-browser-languagedetector";
import "./index.css";
import App from "./App";
import "react-toastify/dist/ReactToastify.css";
import { ToastContainer } from "react-toastify";
//import { RegContextProvider } from "./context/regContext";
// import { LogContextProvider } from "./context/logContext";

import translationEN from "./assets/locale/en/translation.json";
import translationAr from "./assets/locale/ar/translation.json";
import translationHI from "./assets/locale/hi/translation.json";
import translationID from "./assets/locale/id/translation.json";
import translationPL from "./assets/locale/pl/translation.json";
import translationES from "./assets/locale/es/translation.json";
import translationVI from "./assets/locale/vi/translation.json";
import translationZH from "./assets/locale/zh/translation.json";
import translationNl from "./assets/locale/nl/translation.json";
import translationFr from "./assets/locale/fr/translation.json";
import translationJA from "./assets/locale/ja/translation.json";
import translationKO from "./assets/locale/ko/translation.json";
import translationDE from "./assets/locale/de/translation.json";
import consolere from "console-remote-client";
import { PiHandler } from "./components/PiHandler";

// the translations
const resources = {
  en: {
    translation: translationEN,
  },
  de: {
    translation: translationDE,
  },
  ar: {
    translation: translationAr,
  },
  nl: {
    translation: translationNl,
  },
  fr: {
    translation: translationFr,
  },
  hi: {
    translation: translationHI,
  },
  id: {
    translation: translationID,
  },
  ja: {
    translation: translationJA,
  },
  ko: {
    translation: translationKO,
  },
  pl: {
    translation: translationPL,
  },
  es: {
    translation: translationES,
  },
  vi: {
    translation: translationVI,
  },
  zh: {
    translation: translationZH,
  },
};

i18n
  .use(HttpApi)
  .use(initReactI18next)
  .use(LanguageDetector)
  .init({
    resources,
    supportedLngs: [
      "en",
      "ar",
      "de",
      "nl",
      "fr",
      "hi",
      "id",
      "ja",
      "ko",
      "pl",
      "sp",
      "vi",
      "zh",
    ],
    fallbackLng: [
      "en",
      "ar",
      "nl",
      "de",
      "fr",
      "hi",
      "id",
      "ja",
      "ko",
      "pl",
      "sp",
      "vi",
      "zh",
    ],
    detection: {
      order: ["localStorage", "navigator", "htmlTag"],
      caches: ["localStorage"],
    },
    backend: {
      loadPath: "./assets/locale/{{lng}}/translation.json",
    },
    react: { useSuspense: false },
  });

export const server = axios.create({
  baseURL: process.env.REACT_APP_API_URL,

  //baseURL:"https://watugot.org/api/",
  //timeout: 20000,
  headers: {
    ...(process.env.REACT_APP_ENVIROMENT === "DEV"
      ? { "X-whitelist-user": "watugot" }
      : {}),
    "Content-Type": "application/json",
    "Access-Control-Allow-Origin": "*",
    // "Access-Control-Allow-Origin": "http://localhost:3000",
  },
});

let config = {
  headers: {
    ...(process.env.REACT_APP_ENVIROMENT === "DEV"
      ? { "X-whitelist-user": "watugot" }
      : {}),
    "Content-Type": "application/json",
    "Access-Control-Allow-Origin": "*",
    Authorization: `Bearer ${localStorage.getItem("token")}`,
    // "Access-Control-Allow-Origin": "http://localhost:3000",
  },
};

const refreshConfig = () => {
  config = {
    headers: {
      ...(process.env.REACT_APP_ENVIROMENT === "DEV"
        ? { "X-whitelist-user": "watugot" }
        : {}),
      "Content-Type": "application/json",
      "Access-Control-Allow-Origin": "*",
      Authorization: `Bearer ${localStorage.getItem("token")}`,
      // "Access-Control-Allow-Origin": "http://localhost:3000",
    },
  };
};

export { config, refreshConfig };

const consoleReQuerryCode = (() => {
  const urlParams = new URLSearchParams(window.location.search);

  const urlParam = urlParams.get("consoleRe");
  if (urlParam) localStorage.setItem("consoleRe", urlParam);

  return localStorage.getItem("consoleRe");
})();

if (consoleReQuerryCode) {
  consolere.connect({
    server: "https://console.re", // optional, default: https://console.re
    channel: consoleReQuerryCode, // required
    redirectDefaultConsoleToRemote: true, // optional, default: false
    disableDefaultConsoleOutput: true, // optional, default: false
  });
}

ReactDOM.render(
  <React.StrictMode>
    {/* <RegContextProvider> */}
    {/* <LogContextProvider> */}
    <PiHandler />
    <I18nextProvider i18n={i18n}>
      <App />
    </I18nextProvider>
    {/* </LogContextProvider> */}
    {/* </RegContextProvider> */}
    <ToastContainer className="toast-container" />
  </React.StrictMode>,
  document.getElementById("root")
);

if (process.env.REACT_APP_ENVIROMENT === "DEV")
  document.title = "Watugot - Dev";
