import { Product } from "../product/Product";

import "./TakeALook.scss";
import { observer } from "mobx-react-lite";
import { store } from "../../store/index.store";
import { Spin } from "antd";
import { useTranslation } from "react-i18next";
import { IProduct } from "~/common/models";

interface Props {
  randomised?: boolean;
  noTitle?: boolean;
  onlyPi?: boolean;
  products?: IProduct[];
}

export const TakeALook = observer(
  ({ randomised, noTitle, onlyPi, products }: Props) => {
    const { homePageStore, loaderStore } = store;
    const { t } = useTranslation();

    const handleAddToCart = (productID: string) => {
      console.log("[addToCart]", productID);
    };

    const { takeALookItems } = homePageStore;

    return (
      (takeALookItems.length > 0 ||
        products ||
        loaderStore?.isLoadingTakeALook) && (
        <div className="c-take-a-look">
          <div className="c-take-a-look__header">
            {noTitle || (
              <h2 className="c-take-a-look__header-title">
                {t("home-page.takeAlook")}
              </h2>
            )}
          </div>

          <div className="c-take-a-look__list">
            {products || loaderStore?.isLoadingTakeALook ? (
              <div>
                <Spin spinning={true} />
              </div>
            ) : (
              takeALookItems.map((product) => {
                return (
                  <Product
                    key={product.id}
                    mode="vertical"
                    onAddToCart={handleAddToCart}
                    {...product}
                  />
                );
              })
            )}
          </div>
        </div>
      )
    );
  }
);
