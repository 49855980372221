import { observer } from "mobx-react-lite";
import { useEffect, useRef, useState } from "react";
import { useParams } from "react-router-dom";
import { getProductsForUser } from "../../api/products";
import { productMapper } from "../../mappers/productMappers";
import { getImageURL } from "../../common/utils/imageURLgetter";

import "./UsersProductPage.scss";
import { Rate, Spin } from "antd";
import { useBottomScrollListener } from "react-bottom-scroll-listener";
import { Product } from "../../components/product/Product";
import { PathBasedBreadcrumb } from "../../components/PathBasedBreadcrumb/PathBasedBreadcrumb";

export const UsersProductPage = observer(() => {
  const [userdata, setUserdata] = useState<Record<string, any>>({});
  const [isLoading, setIsLoading] = useState(false);
  const [products, setProducts] = useState<any[]>([]);

  const { userId } = useParams();

  const currentlyLoadedProducts = useRef(0);

  const stopLoading = useRef(false);

  useEffect(() => {
    (async () => {
      if (!userId) return;
      setIsLoading(true);
      const productsReq = await getProductsForUser(
        userId,
        currentlyLoadedProducts.current,
        currentlyLoadedProducts.current + 10
      );

      currentlyLoadedProducts.current =
        products?.length + productsReq?.productObj.length;

      setProducts((prods) => [
        ...prods,
        ...productsReq?.productObj.map(productMapper),
      ]);

      if (productsReq?.userData) setUserdata(productsReq?.userData);
      setIsLoading(false);
    })();
  }, []);

  const loadProducts = async () => {
    try {
      if (!userId || stopLoading.current) return;

      setIsLoading(true);
      const productsReq = await getProductsForUser(
        userId,
        currentlyLoadedProducts.current,
        currentlyLoadedProducts.current + 10
      );

      if (productsReq?.productObj.length !== 10) stopLoading.current = true;

      currentlyLoadedProducts.current =
        products?.length + productsReq?.productObj.length;

      setProducts((prods) => [
        ...prods,
        ...productsReq?.productObj.map(productMapper),
      ]);
    } catch (e) {
      console.log(e);
    } finally {
      setIsLoading(false);
    }
  };

  useBottomScrollListener(
    () => {
      loadProducts();
      console.log("callback");
    },
    {
      offset: 0,
      debounce: 200,
      debounceOptions: { leading: true },
      triggerOnNoScroll: false,
    }
  );

  function handleAddToCart(productID: string): void {}

  return (
    <div className="p-user-listings">
      <PathBasedBreadcrumb
        customEnd={userdata?.name}
        universalRedirect="/home-page"
      />
      <Spin spinning={isLoading}>
        <div className="p-user__profile">
          <img
            className="p-user__profile-image"
            src={getImageURL(userdata?.profileImage)}
            alt="profile"
          />

          <div className="p-user__profile-details">
            <div className="p-user__profile-details-name-wraper">
              <p className="p-user__profile-details-name">{userdata?.name} </p>
              <span className="p-user__profile-details__status">
                <div
                  className={`p-user__profile-details__status-${!!userdata?.onlineStatus}`}
                />
              </span>
            </div>

            <div className="p-user__profile-details-reviews">
              <span>
                <Rate
                  disabled
                  defaultValue={0}
                  className="c-product-page_home_rating_row_star"
                  style={{ color: "black", fontSize: 15 }}
                  value={userdata?.rating}
                />
              </span>
              <span>|</span>
              <span>{userdata?.reviewsCount} Reviews</span>
            </div>
            <p className="p-user__profile-details-piname">
              Pi Network Username: {userdata?.piUsername || "-"}
            </p>
          </div>
        </div>
        <div className="p-user-listings__products">
          {products.map((product, i) => {
            return (
              <Product
                key={product.id + i}
                mode="vertical"
                onAddToCart={handleAddToCart}
                {...product}
              />
            );
          })}
        </div>
      </Spin>
    </div>
  );
});
