import { Button, Col, Row, Spin } from "antd";
import classNames from "classnames";
import { observer } from "mobx-react-lite";
import { store } from "../../store/index.store";
import "./ItemsPage.scss";
import { useEffect, useRef, useState } from "react";
import { IProduct } from "../../common/models";
import { Product } from "../../components/product/Product";
import { getProductsBy } from "../../api/products";
import { productMapper } from "../../mappers/productMappers";
import { useBottomScrollListener } from "react-bottom-scroll-listener";
import { useTranslation } from "react-i18next";
import { ShoppingCartOutlined } from "@ant-design/icons";

export const ItemsPage = observer(() => {
  const { t } = useTranslation();
  const { UIStore } = store;
  const [isLoading, setIsLoading] = useState(false);
  const [products, setProducts] = useState<IProduct[]>([]);

  const { setSelectedCategory, selectedCategory } = UIStore;

  const { authStore } = store;

  const currentlyLoadedProducts = useRef(0);
  const stopLoading = useRef(false);

  const loadProducts = async () => {
    try {
      setIsLoading(true);

      const currentlyLoaded = currentlyLoadedProducts.current;

      if (stopLoading.current) return;

      const productsReq = await getProductsBy(
        currentlyLoadedProducts.current,
        currentlyLoadedProducts.current + 10,
        {
          condition: selectedCategory,
          location: authStore.location?.map((loc) => loc.toString()),
        }
      );

      currentlyLoadedProducts.current =
        products?.length + productsReq?.productObj.length;

      if (productsReq?.productObj.length < 10) stopLoading.current = true;

      if (currentlyLoaded === 0)
        setProducts(productsReq?.productObj.map(productMapper));
      else
        setProducts((prods) => [
          ...prods,
          ...productsReq?.productObj.map(productMapper),
        ]);
    } catch (e) {
      console.log(e);
    } finally {
      setIsLoading(false);
    }
  };

  useEffect(() => {
    setProducts([]);
    stopLoading.current = false;
    currentlyLoadedProducts.current = 0;

    if (
      authStore.isLoggedIn &&
      !authStore.location?.map((loc) => loc.toString())
    )
      return;

    loadProducts();
  }, [selectedCategory, authStore.location, authStore.isLoggedIn]);

  useBottomScrollListener(
    () => {
      loadProducts();
      console.log("callback");
    },
    {
      offset: 0,
      debounce: 200,
      debounceOptions: { leading: true },
      triggerOnNoScroll: false,
    }
  );

  function handleAddToCart(productID: string): void {}

  return (
    <div className="p-items-page">
      <Row className="c-category-page__header-row">
        <Col span={12} key="1">
          <Button
            className={classNames(
              "c-category-page__button",
              "c-category-page__button--left",
              {
                "c-category-page__button--selected": selectedCategory === "new",
                "c-category-page__button--unselected":
                  selectedCategory === "used",
              }
            )}
            onClick={() => setSelectedCategory("new")}
          >
            {t("new")}
          </Button>
        </Col>
        <Col span={12} key="2">
          <Button
            className={classNames(
              "c-category-page__button",
              "c-category-page__button--right",
              {
                "c-category-page__button--selected":
                  selectedCategory === "used",
                "c-category-page__button--unselected":
                  selectedCategory === "new",
              }
            )}
            onClick={() => setSelectedCategory("used")}
          >
            {t("used")}
          </Button>
        </Col>
      </Row>
      <div className="p-category-page">
        {products.length > 0 ? (
          products.map((product, i) => {
            return (
              <Product
                key={product.id + i}
                mode="vertical"
                onAddToCart={handleAddToCart}
                {...product}
              />
            );
          })
        ) : (
          <>
            <div className="p-search__items__container-empty">
              <ShoppingCartOutlined className="p-search__items__container-empty-icon" />
              <div>No products found in your area</div>
            </div>
          </>
        )}
      </div>

      <Spin spinning={isLoading} />
    </div>
  );
});
