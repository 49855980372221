//libraries imports
import { Link, NavLink, useLocation, useNavigate } from "react-router-dom";
import { useEffect, useState } from "react";
import { observer } from "mobx-react-lite";
import Hamburger from "hamburger-react";

//custom components/handlers/utils imports
import { Form, Button, Select, Drawer, Row } from "antd";
import {
  ArrowRightOutlined,
  // GlobalOutlined,
  HomeOutlined,
  SearchOutlined,
  UploadOutlined,
} from "@ant-design/icons";
import { store } from "../../store/index.store";

// styles imports
import "./Navbar.scss";

import Logo from "../../assets/images/logo.svg";
import { useTranslation } from "react-i18next";
//import { CODE_TO_LANG } from "../../common/constants/languages";

export const Navbar = observer(() => {
  const { UIStore } = store;
  const [key, setKey] = useState(0);
  const navigate = useNavigate();

  const [selectedCondition, setSelectedCondition] = useState(null);
  const [selectedCategory, setSelectedCategory] = useState(null);

  const showDrawer = () => {
    UIStore.setShowSearch(false);
    setKey((prevKey: number) => prevKey + 1);
    UIStore.setDrawerHeight("160px");
    UIStore.setDisplayAdvanceSearchButton(true);
    UIStore.setDisplaySearchButton(false);
    UIStore.setOpen(true);
  };

  const onClose = () => {
    UIStore.setOpen(false);
  };

  function handleSearchClick() {
    UIStore.setShowSearch(!UIStore.showSearch);
    UIStore.setDisplayAdvanceSearchButton(!UIStore.displayAdvanceSearchButton);
    UIStore.setDisplaySearchButton(!UIStore.displaySearchButton);
    UIStore.setDrawerHeight("450px");
  }

  const location = useLocation();

  //go not show header in promo page

  const searchItems = (e: any) => {
    UIStore.setSearchValue(e.target.value);
  };

  const goToSearch = () => {
    if (!UIStore.searchValue) return;

    let querry = `?`;
    if (UIStore.searchValue) querry += `querry=${UIStore.searchValue}`;
    if (selectedCondition) querry += `&condition=${selectedCondition}`;
    if (selectedCategory) querry += `&category=${selectedCategory}`;

    UIStore.setOpen(false);

    navigate(`/search${querry}`);
  };

  useEffect(() => {
    if (UIStore.open) UIStore.loadCategories();
  }, [UIStore.open]);

  const { t, i18n } = useTranslation();

  const onLanguageChange = (lang: string) => {
    console.log(lang);
    i18n.changeLanguage(lang);
  };

  if (location.pathname === "/") {
    return null;
  }

  return (
    <>
      <Drawer
        placement="top"
        closable={false}
        onClose={onClose}
        open={UIStore.open}
        key={"left"}
        height={UIStore.drawerHeight}
      >
        <Form
          key={key}
          className="c-navbar__search-form"
          name="dynamic_form_nest_item"
          autoComplete="off"
          layout="vertical"
        >
          <div className="c-navbar__search-form-placeholder">
            <Form.Item className="c-navbar__search-form-placeholder-item">
              <label className="c-navbar__search-form-placeholder-item-label">
                {t("searchbar.keywords")}
              </label>
              <Row className="c-navbar__search-form-placeholder-item-row">
                <input
                  className="c-navbar__search-form-placeholder-item-search"
                  placeholder={t("your_search_querry_placeholder") || ""}
                  onChange={searchItems}
                  onSubmit={goToSearch}
                  onKeyDown={(e) => e.key === "Enter" && goToSearch()}
                />
                <SearchOutlined
                  className="search-icon"
                  style={{
                    right: 10,
                    fontSize: "20px",
                    color: !!UIStore.searchValue ? "#ffb44b" : "unset",
                    transitionDuration: "0.5s",
                  }}
                  onClick={goToSearch}
                />
              </Row>
            </Form.Item>
          </div>
          <Form.List name="option_details">
            {(fields, { add }) => (
              <>
                {fields.map(
                  ({ key }) =>
                    UIStore.showSearch && (
                      <>
                        <label
                          className="c-navbar__search-form-placeholder-item-label"
                          htmlFor="condition"
                        >
                          {t("searchbar.type")}
                        </label>
                        <Form.Item
                          className="c-navbar__search-form-placeholder-item"
                          id="condition"
                        >
                          <Select
                            key="condition"
                            bordered={false}
                            className="c-navbar__search-form-placeholder-item-select"
                            placeholder="Used"
                            value={selectedCondition}
                            onChange={(value) => {
                              setSelectedCondition(value);
                            }}
                            options={[
                              { value: "new", label: "New" },
                              { value: "used", label: "Used" },
                            ]}
                          />
                        </Form.Item>
                        <label
                          className="c-navbar__search-form-placeholder-item-label"
                          htmlFor="category"
                        >
                          {t("searchbar.category")}
                        </label>
                        <Form.Item
                          className="c-navbar__search-form-placeholder-item"
                          id="category"
                        >
                          <Select
                            key="category"
                            bordered={false}
                            className="c-navbar__search-form-placeholder-item-select"
                            placeholder="Category"
                            value={selectedCategory}
                            onChange={(value) => {
                              setSelectedCategory(value);
                            }}
                            options={UIStore.categories?.map((category) => ({
                              value: category._id,
                              label: category.name,
                            }))}
                          />
                        </Form.Item>
                        {/* <Form.Item className="c-navbar__search-form-placeholder-item">
                          <label className="c-navbar__search-form-placeholder-item-label">
                            Location
                          </label>
                          <Row>
                            <input
                              className="c-navbar__search-form-placeholder-item-search"
                              placeholder="Delhi"
                            />
                            <img
                              alt="location"
                              className="location-icon"
                              src={location}
                              width="30px"
                              height={"30px"}
                              style={{ right: 10 }}
                            />
                          </Row>
                        </Form.Item> */}
                      </>
                    )
                )}
                <Form.Item>
                  {UIStore.displayAdvanceSearchButton && (
                    <Button
                      className="c-navbar__search-form-advance-search-button"
                      type="primary"
                      shape="round"
                      onClick={() => {
                        add();
                        handleSearchClick();
                      }}
                    >
                      {t("searchbar.advanced")}
                      <ArrowRightOutlined className="advance-search-arrow" />
                    </Button>
                  )}
                  {UIStore.displaySearchButton && (
                    <Button
                      className="c-navbar__search-form-search-button"
                      type="primary"
                      shape="round"
                      onClick={goToSearch}
                    >
                      {t("searchbar.search")}
                    </Button>
                  )}
                </Form.Item>
              </>
            )}
          </Form.List>
        </Form>
      </Drawer>
      <nav className="c-navbar">
        <div
          className={`c-navbar__hamburger ${
            ["/login", "/reset-password"].includes(location.pathname) &&
            "c-navbar__hamburger--login"
          }`}
        >
          <Hamburger
            toggled={UIStore.isUserOverlayOpen}
            onToggle={() => {
              UIStore.setIsUserOverlayOpen(!UIStore.isUserOverlayOpen);
            }}
            size={20}
          />

          {/* <GlobalOutlined
            //  style={{ margin: "auto 16px auto 18px" }}
            className="c-navbar__hamburger-globe"
          /> */}
          {i18n.languages && (
            <Select
              className="c-navbar__hamburger-language"
              onChange={onLanguageChange}
              defaultValue={i18n.languages[0]}
              options={i18n.languages.map((lan) => ({
                value: lan,
                label: t(lan),
              }))}
            />
          )}

          {["/login", "/reset-password"].includes(location.pathname) && (
            <Link to="/home-page" className="c-navbar__hamburger-home">
              <HomeOutlined />
            </Link>
          )}
        </div>
        {!["/login", "/reset-password"].includes(location.pathname) && (
          <>
            <Link to="/home-page" className="c-navbar__logo">
              <img src={Logo} alt="logo" className="c-navbar__logo-image" />
            </Link>
            <div className="c-navbar__left">
              <Link to="/post-product" className="c-navbar__left-upload">
                <UploadOutlined style={{ fontSize: "20px" }} />
              </Link>
              <div className="c-navbar__left-search" onClick={showDrawer}>
                <SearchOutlined style={{ fontSize: "20px" }} />
              </div>
            </div>
          </>
        )}
      </nav>
      <nav className="c-sub-nav">
        <NavLink className={"c-sub-nav__link"} to="/home-page">
          {t("home-page.featured")}
        </NavLink>
        <NavLink className={"c-sub-nav__link"} to="/categories">
          {t("home-page.categories")}
        </NavLink>
        <NavLink className={"c-sub-nav__link"} to="/stores">
          {t("home-page.stores")}
        </NavLink>
        <NavLink className={"c-sub-nav__link"} to="/items">
          {t("home-page.items")}
        </NavLink>
      </nav>
    </>
  );
});
