import type { APIProduct } from "~/api/products";
import { IProduct } from "~/common/models";

export const productMapper = (product: APIProduct): IProduct => {
  // const links = product?.image ? product.image?.split(",") : [];
  return {
    id: product?._id || "",
    name: product?.title || "",
    price: product?.price || -1,
    description: product?.description || "",
    currency: "PI",
    images: product?.images || [],
    condition: product?.condition === "new" ? "new" : "used",
    userId: product?.userId || "",
    coordinates: product?.location?.coordinates || [0, 0],
  };
};
