import { makeAutoObservable } from "mobx";
import { getProfile, updateProfile } from "../api/profile";
import { config, refreshConfig, server } from "..";
import {
  addToWishlist,
  deleteFromWishlist,
  getWishlist,
} from "../api/wishlist";

interface UserProfile {
  createdAt: number;
  email: string;
  fullName: string;
  isActive: boolean;
  userId: string;
  pi_username: string;
  profileImage: string;
  address1: string;
  address2: string;
  city: string;
  country: string;
  state: string;
  unreviewedTransactions?: string[];
  location?: any;
  walletAddress?: string;
  phone?: string;
  mailPreference?: boolean;
}
interface LoginForm {
  email: string;
  password: string;
  agree: boolean;
  passwordConfirmation: string;
  name: string;
  addToHomeScreen: boolean;
  otp: string;
}

const EMPTY_USER_PROFILE: UserProfile = {
  createdAt: 0,
  email: "",
  fullName: "",
  isActive: false,
  userId: "",
  pi_username: "",
  profileImage: "",
  address1: "",
  address2: "",
  city: "",
  country: "",
  state: "",
  unreviewedTransactions: [],
};

interface Wish {
  userId: string;
  itemId: string;
}
export class AuthStore {
  userName = "";
  password = "";
  isLoggedIn = false;
  loaderStore: any;
  wishlist: Wish[] = [];

  location: [number, number, number] | undefined = undefined;

  form: LoginForm = {
    email: "",
    password: "",
    agree: false,
    passwordConfirmation: "",
    name: "",
    addToHomeScreen: false,
    otp: "",
  };

  authError = "";

  userProfile: UserProfile = EMPTY_USER_PROFILE;

  transactionsData: Record<string, any> = {};

  canceledTxReviews: string[] = [];

  constructor(loaderStore: any) {
    makeAutoObservable(this);
    this.loaderStore = loaderStore;
  }

  cancelTxReview = async (txId: string) => {
    this.canceledTxReviews = [...this.canceledTxReviews, txId];
  };

  loadTxData = async (txId: string) => {
    this.loaderStore.setIsLoading("isLoadingTxData", true);

    try {
      const { data } = await server.get(
        `${process.env.REACT_APP_API_URL}/payments/${txId}`,
        config
      );

      this.transactionsData[txId] = data.payment;
    } catch (e) {
    } finally {
      this.loaderStore.setIsLoading("isLoadingTxData", false);
    }
  };

  addWish = async (productId: string) => {
    this.loaderStore.setIsLoading("isEditingWishes", true);

    try {
      if (this.wishlist.some((item) => item.itemId === productId))
        await deleteFromWishlist(productId);
      else await addToWishlist(productId);
      this.loadWishes();
    } catch (e) {
      console.error(e);
    } finally {
      this.loaderStore.setIsLoading("isEditingWishes", false);
    }
  };

  loadWishes = async () => {
    this.loaderStore.setIsLoading("isLoadingWishes", true);
    try {
      const { data } = await getWishlist();

      this.wishlist = data.data;
    } catch (e) {
    } finally {
      this.loaderStore.setIsLoading("isLoadingWishes", false);
    }
  };

  setAuthError = (error: string) => {
    this.authError = error;
  };

  clearAuthError = () => {
    this.authError = "";
  };

  setForm = (key: keyof LoginForm, value: string | boolean) => {
    this.form[key] = value as never;
  };

  setUserName(userName: string) {
    this.userName = userName;
  }

  get hasLocation() {
    return this.location?.length === 3;
  }

  checkIfLoggedIn = async () => {
    const token = localStorage.getItem("token");

    if (!token) return false;

    try {
      const { data } = await getProfile();

      if (data.data.email) {
        this.userProfile = {
          ...data.data,
          location: this.userProfile.location,
        };

        this.isLoggedIn = true;

        return true;
      } else {
        this.isLoggedIn = false;
        return false;
      }
    } catch (e: any) {
      this.isLoggedIn = false;
      localStorage.removeItem("token");
      console.error(e);
      return false;
    }
  };

  setUserProfile = (profile: Partial<UserProfile>) => {
    this.userProfile = { ...this.userProfile, ...profile };
  };

  login = async () => {
    this.isLoggedIn = false;
    this.loaderStore.setIsLoading("isLoggingIn", true);
    this.clearAuthError();

    try {
      if (!this.form.agree)
        throw new Error("Please agree to the terms and conditions");

      const { data } = await server.post(
        `${process.env.REACT_APP_API_URL}/login`,
        {
          email: this.form.email.toLowerCase(),
          password: this.form.password,
        }
      );

      const token = data?.data?.response?.token;

      if (!token) return;

      this.isLoggedIn = true;
      localStorage.setItem("token", token);

      refreshConfig();

      this.checkIfLoggedIn();
    } catch (e: any) {
      console.log(e.response);
      this.setAuthError(
        e?.response?.data?.data?.response?.message ||
          e?.message ||
          "An error occurred, please try again"
      );
    } finally {
      this.loaderStore.setIsLoading("isLoggingIn", false);
    }
  };

  updateProfilePic = async (pid: string) => {
    const data = {
      profileImage: pid,
    };

    this.loaderStore.setIsLoading("isLoadingProfilePicture", true);

    try {
      await updateProfile(data);

      setTimeout(() => {
        this.userProfile.profileImage = pid;
        this.loaderStore.setIsLoading("isLoadingProfilePicture", false);
      }, 5000);
    } catch (e) {
      this.loaderStore.setIsLoading("isLoadingProfilePicture", false);
    }
  };

  logOut = () => {
    this.isLoggedIn = false;
    localStorage.removeItem("token");
    window.location.href = "/";
  };

  setLocation = (location: [number, number, number] | undefined) => {
    console.log("authstore", location);

    this.location = location;
  };
}
