import { EditOutlined, DeleteOutlined } from "@ant-design/icons";
import { Carousel } from "antd";
import { IProduct } from "../../common/models";
import { getImageURL } from "../../common/utils/imageURLgetter";

import "./EditableProduct.scss";
import { Link } from "react-router-dom";

export const EditableProduct = ({
  product,
  onDelete,
  onEdit,
}: {
  product: IProduct;
  onDelete: () => void;
  onEdit: () => void;
}) => {
  return (
    <div className="c-editable-product">
      <Link to={`/product/${product.id}`} className="c-editable-product__link">
        <>
          <div>
            <Carousel className="c-editable-product__images" autoplay>
              {product?.images?.map((imageLink, index) => (
                <img
                  src={getImageURL(imageLink)}
                  alt={product?.name}
                  key={index}
                  className="c-product__image"
                />
              ))}
            </Carousel>
          </div>
          <div className="c-editable-product__info">
            <p className="c-editable-product__info-name">{product.name}</p>
            <p className="c-editable-product__info-description">
              {product?.description}
            </p>
            <p className="c-editable-product__info-price">
              {product.price} {product.currency}
            </p>
          </div>
        </>
      </Link>

      <div className="c-editable-product__icons">
        <EditOutlined
          className="c-editable-product__icons-icon"
          onClick={onEdit}
        />
        <DeleteOutlined
          className="c-editable-product__icons-icon"
          onClick={onDelete}
        />
      </div>
    </div>
  );
};
