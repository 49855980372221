import { useState, useEffect, useMemo } from "react";
import {
  Alert,
  Breadcrumb,
  Button,
  Carousel,
  Checkbox,
  Input,
  Modal,
  Rate,
  Row,
  Spin,
} from "antd";
import { observer } from "mobx-react-lite";
import "./ProductPage.scss";
import { useTranslation } from "react-i18next";
import Forward from "../../assets/images/forward.png";
import ecoImage from "../../assets/images/eco-image.png";
import {
  ArrowLeftOutlined,
  EditOutlined,
  EnvironmentOutlined,
  FlagOutlined,
  HeartFilled,
  HeartOutlined,
} from "@ant-design/icons";
import { Link, useNavigate, useParams } from "react-router-dom";
import { getProductById } from "../../api/products";
import { productMapper } from "../../mappers/productMappers";
import { IProduct } from "../../common/models";
import { getImageURL } from "../../common/utils/imageURLgetter";
import { store } from "../../store/index.store";
import { flagProd } from "../../api/flag";
import { buyItem } from "../../common/utils/piUtils";
import { addRedirect } from "../../api/redirect";
import { toast } from "react-toastify";
import { getDistanceFromLatLonInKm } from "../../common/utils/distanceFromLocation";

export const ProductPage = observer(() => {
  const { t } = useTranslation();
  const [product, setProduct] = useState<IProduct>();
  const [isPaymentModalOpen, setIsPaymentModalOpen] = useState(false);
  const [isFlagModalOpen, setFlagModalOpen] = useState(false);
  const [flagReason, setFlagReason] = useState("");
  const [error, setError] = useState("");
  const [sellerData, setSellerData] = useState({} as any);

  const [checkboxState, setCheckboxState] = useState({
    chatWithSeller: false,
    fixedPlace: false,
    inspectedTheItem: false,
    satisfied: false,
  });

  const navi = useNavigate();

  const { authStore, loaderStore, UIStore } = store;
  const { isLoadingWishes, isEditingWishes } = loaderStore;
  const { isLoggedIn } = authStore;

  const isOwnProduct = useMemo(() => {
    return product?.userId === authStore.userProfile?.userId;
  }, [product, authStore.userProfile]);

  const { addWish, wishlist } = authStore;
  let { id } = useParams();

  const isProductInWishlist = useMemo(() => {
    return wishlist.some((item) => item.itemId === id);
  }, [wishlist, id]);

  useEffect(() => {
    (async () => {
      if (!id) return;
      const product = await getProductById(id);
      setProduct(productMapper(product?.productObj));
      setSellerData(product?.sellerData);
    })();
  }, [id]);

  const {
    name: productName,
    price: productPrice,
    images,
    description: productDescription,
  } = product || {};

  const productDataToShare = {
    title: t("productPage.share.title", {
      productName,
    }),
    text: t("productPage.share.text", {
      productName,
    }),
    url: window.location.href,
  };

  const share = async () => {
    if (window.Pi.api.accessToken) {
      window.Pi.openShareDialog(
        t("productPage.share.piTitle", {
          productName,
        }),
        t("productPage.share.piText", {
          productName,
          href: window.location.href,
        })
      );
      return;
    }

    if (!navigator.share) return;
    try {
      await navigator.share(productDataToShare);
    } catch (error) {
      console.log(error);
    }
  };

  const flagProduct = async () => {
    if (!id || !flagReason) return;
    await flagProd({ productId: id, reason: flagReason });
    toast.success("Product has been flagged");
    setFlagReason("");
    setFlagModalOpen(false);
  };

  const createPayment = async () => {
    if (!window.isPiAuth) {
      const token = encodeURIComponent(localStorage.getItem("token") || "");
      const path = encodeURIComponent(window.location.href);

      console.log("token", token, "path", path);

      window.location.href = `pi://watugot.net/tokenLogin?token=${token}&next=${path}`;
    }

    setError("");
    if (
      checkboxState.chatWithSeller &&
      checkboxState.fixedPlace &&
      checkboxState.inspectedTheItem &&
      checkboxState.satisfied
    ) {
      console.log("Payment created");

      if (
        !productName ||
        !productPrice ||
        !id ||
        !authStore?.userProfile?.userId
      )
        return setError(t("errors.paymentInitErr") || "");

      buyItem({
        productName: productName,
        productPrice: productPrice,
        id: id,
        buyerId: authStore.userProfile.userId,
        productId: id,
        redrUserOnComplete: () => {
          navi("/home-page");
          UIStore.setPaymentStartedModal(true);
        },
      });

      setIsPaymentModalOpen(false);
    } else {
      setError(t("errors.tickAll") || "");
    }
  };

  const handleWishlist = async () => {
    try {
      if (!id) return;

      const res = addWish(id);
      console.log(res);
    } catch (err) {
      console.log(err);
    }
  };

  const prodDistance = getDistanceFromLatLonInKm(
    (authStore?.location?.slice(0, 2) as [number, number]) || [0, 0],
    [product?.coordinates[1] || 0, product?.coordinates[0] || 0]
  );

  const openPaymentModal = () => {
    if (prodDistance > 50) {
      return toast.error("You are too far away to buy this product");
    }
    setIsPaymentModalOpen(true);
  };

  const closePaymentModal = () => {
    setIsPaymentModalOpen(false);
  };

  const redirectUser = async () => {
    const token = encodeURIComponent(localStorage.getItem("token") || "");
    const path = encodeURIComponent(window.location.href);

    const { data: redirect } = await addRedirect(token, path);

    const id = redirect?.id;
    const encryptionKey = redirect?.encryptionKey;

    window.location.href = `pi://watugot.net/tokenLogin/${id}/${encryptionKey}`;
  };

  return (
    <div className="c-product-page_home">
      <Row className="c-product-page_home_navigation">
        <Breadcrumb separator=" " className="breadcrumb">
          <Breadcrumb.Item href="/home-page">
            <ArrowLeftOutlined />
          </Breadcrumb.Item>
          <Breadcrumb.Item className="productPageTitleBreadcrumb">
            {productName}
          </Breadcrumb.Item>
        </Breadcrumb>
        <div
          style={{ cursor: "pointer" }}
          className="c-product-page_home_navigation_forward"
          onClick={() => {
            share();
          }}
        >
          <img
            alt="forward"
            src={Forward}
            className="c-product-page_home_navigation_forward_image"
          ></img>
        </div>
      </Row>
      {isOwnProduct && (
        <span className="c-product-page_home_navigation-condition">
          {t("productPage.thankYou")}
        </span>
      )}
      <span className="c-product-page_home_navigation-condition">
        <b>{product?.condition === "new" ? t("new") : t("used")}</b>
      </span>
      <div className="c-product-page_home_carousel_icons">
        {isOwnProduct ? (
          <span>
            <Link to={`/edit-product/${product?.id}`}>
              <EditOutlined />
            </Link>
          </span>
        ) : (
          <span onClick={() => setFlagModalOpen(true)}>
            <FlagOutlined />
          </span>
        )}
      </div>

      <Carousel className="c-product-page_home_carousel" autoplay>
        {images?.map((imageLink, index) => (
          <img
            src={getImageURL(imageLink, false)}
            alt={`${imageLink}-${index}`}
            key={index}
            className="c-product-page_home_carousel-image"
          />
        ))}
        {images?.length === 0 && (
          <img
            src={getImageURL("", false)}
            alt={`${{ productName }}`}
            className="c-product-page_home_carousel-image"
          />
        )}
      </Carousel>

      <div className="c-product-page_home_eco-friendly">
        <Row className="c-product-page_home_eco-friendly-first">
          <img
            className="c-product-page_home_eco-friendly-first-image"
            src={ecoImage}
            alt="product"
            style={{ margin: "10px", display: "inline-block" }}
          ></img>
          <label className="c-product-page_home_eco-friendly-first-label">
            {t("productPage.importantTitle")}
          </label>
        </Row>
        <Row className="c-product-page_home_eco-friendly-second-label">
          <label>{t("productPage.chatWithSeller")} </label>
        </Row>
      </div>

      <div className="c-product-page_home_seller-text">
        {t("productPage.soldBy")}
      </div>
      <div className="c-product-page_home_seller-name">
        <Link
          to={`/user/products/${product?.userId}`}
          className="c-product-page_home_seller-name-text"
        >
          <b>{sellerData?.name}</b>
        </Link>
      </div>
      <Row className="c-product-page_home_rating">
        <div className="c-product-page_home_rating_row">
          <Rate
            disabled
            defaultValue={0}
            className="c-product-page_home_rating_row_star"
            style={{ color: "black", fontSize: 15 }}
            value={sellerData?.rating}
          />
          <label className="c-product-page_home_rating_row_star_label">
            {sellerData?.rating}/5
          </label>
        </div>
        <div className="c-product-page_home_rating_row-reviews">
          {isLoggedIn && (
            <div>
              <EnvironmentOutlined /> {prodDistance.toFixed(1)} km away
            </div>
          )}
          <label className="c-product-page_home_rating_row_star_reviews">
            {t("reviews", { count: sellerData?.reviewsCount || 0 })}
          </label>
        </div>
      </Row>
      <div className="c-product-page_home_product">
        <div key="1" className="c-product-page_home_product-name">
          <label>{productName}</label>
        </div>

        {isOwnProduct || (
          <div key="2" className="c-product-page_home_product-name-like">
            <Spin spinning={isLoadingWishes || isEditingWishes}>
              {isProductInWishlist ? (
                <HeartFilled className="hearts" onClick={handleWishlist} />
              ) : (
                <HeartOutlined className="hearts" onClick={handleWishlist} />
              )}
            </Spin>
          </div>
        )}
      </div>
      <div className="c-product-page_home_price">
        <label>{productPrice} Pi</label>
      </div>
      {/* <div className="c-product-page_home_quantity">
        <label>1ps</label>
      </div> */}
      <div className="c-product-page_home_description">
        <label>{productDescription}</label>
      </div>

      {isOwnProduct || (
        <>
          <Button
            disabled={!isLoggedIn}
            type="primary"
            shape="round"
            className="c-product-page_home_button-add-item"
            style={{ backgroundColor: "#159a8c !important" }}
            onClick={() => {
              navi(`/chats/product/${product?.id}`);
            }}
          >
            {t("Chat_with_Seller")}
          </Button>
          <Button
            disabled={!isLoggedIn}
            id="piecardBtn"
            type="primary"
            shape="round"
            className="c-product-page_home_button-add-item btn-piecard"
            style={{ backgroundColor: "#b44afb !important" }}
            onClick={openPaymentModal}
          >
            {t("Pay_with_Pi")}
          </Button>
        </>
      )}

      <Modal
        open={isPaymentModalOpen}
        onCancel={closePaymentModal}
        onOk={closePaymentModal}
        title="Payment Modal"
      >
        <h2>{t("productPage.tickThatApply")}</h2>
        {error && (
          <>
            <Alert type="error" message={error} />
            <br />
          </>
        )}
        <div className="div-checkbox">
          <span>
            <Checkbox
              type="checkbox"
              name="chatWithSeller"
              onClick={(e: any) => {
                setCheckboxState({
                  ...checkboxState,
                  chatWithSeller: e.target?.checked,
                });
              }}
            />
            {t("productPage.chattedSeller")}
          </span>
          <span>
            <Checkbox
              type="checkbox"
              name="fixedPlace"
              onClick={(e: any) => {
                setCheckboxState({
                  ...checkboxState,
                  fixedPlace: e.target?.checked,
                });
              }}
            />
            {t("productPage.fixedAlocation")}
          </span>
          <span>
            <Checkbox
              type="checkbox"
              name="inspectedTheItem"
              onClick={(e: any) => {
                setCheckboxState({
                  ...checkboxState,
                  inspectedTheItem: e.target?.checked,
                });
              }}
            />
            {t("productPage.inspectedTheItem")}
          </span>
          <span>
            <Checkbox
              type="checkbox"
              name="satisfied"
              onClick={(e: any) => {
                setCheckboxState({
                  ...checkboxState,
                  satisfied: e.target?.checked,
                });
              }}
            />
            {t("productPage.tatisfied")}
          </span>
        </div>
        <p>{t("productPage.note")}</p>
        <div className="button-main">
          {window.isPiAuth ? (
            <Button
              onClick={createPayment}
              type="primary"
              className="link-success"
            >
              Pay with Pi
            </Button>
          ) : (
            <Button
              onClick={redirectUser}
              type="primary"
              className="link-success"
              // href={`pi://watugot.org/tokenLogin?token=${encodeURIComponent(
              //   localStorage.getItem("token") || ""
              // )}&next=${encodeURIComponent(window.location.href)}`}
            >
              Pay with Pi
            </Button>
          )}
        </div>
      </Modal>
      <Modal
        open={isFlagModalOpen}
        onCancel={() => setFlagModalOpen(false)}
        onOk={flagProduct}
      >
        Please enter your concern with this product
        <Input
          value={flagReason}
          onChange={(e) => {
            setFlagReason(e.target.value);
          }}
        />
      </Modal>

      <br />
      <br />
    </div>
  );
});
