import { config, server } from "../../index";

interface ToBuyProduct {
  productName: string;
  id: string;
  productPrice: number;
  buyerId: string;
  productId: string;
  redrUserOnComplete?: () => void;
}

export const buyItem = async ({
  productName,
  productPrice,
  id,
  buyerId,
  productId,
  redrUserOnComplete,
}: ToBuyProduct) => {
  await window?.Pi?.createPayment(
    {
      amount: Number(productPrice),
      memo: `Watugot transaction for: ${productName}`,
      metadata: {
        id,
        buyerId,
        productId,
      },
    },
    {
      onReadyForServerApproval: async function (paymentId: string) {
        console.log("onReadyForServerApproval", paymentId);
        return await server.post(`/approve/paymentId/${paymentId}`, {}, config);
      },
      onReadyForServerCompletion: async function (
        paymentId: string,
        txid: string
      ) {
        console.log("onReadyForServerCompletion", paymentId, txid);

        redrUserOnComplete?.();
        return await server.post(
          `/complete/paymentId/${paymentId}/txid/${txid}`,
          {},
          config
        );
      },
      onCancel: async function (paymentId: string) {
        console.log("onCancel", paymentId);
        return await server.post(`/cancel/paymentId/${paymentId}`, {}, config);
      },
      onError: function (error: any, payment: string) {
        console.log(error, payment);
      },
    }
  );
};
