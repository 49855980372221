import { observer } from "mobx-react-lite";
import { useNavigate } from "react-router-dom";

import "./VerifyOTP.scss";
import { Alert, Button, Input } from "antd";
import { useEffect, useState } from "react";
import { store } from "../../store/index.store";

import axios from "axios";
import { UserOutlined } from "@ant-design/icons";
import { config } from "../../index";

export const VerifyOTP = observer(() => {
  const navigate = useNavigate();

  const { authStore } = store;

  const { form } = authStore;

  const { email, otp } = form;

  const [error, setError] = useState("");

  const [hadEmptyEmail, setHadEmptyEmail] = useState(false);

  useEffect(() => {
    if (!email) {
      setHadEmptyEmail(true);
    }
  }, []);

  console.log(hadEmptyEmail);

  const nextStep = () => {
    (async () => {
      try {
        const { data } = await axios.patch(
          `${process.env.REACT_APP_API_URL}/otp`,
          {
            email: email.toLowerCase(),
            otp,
          },
          config
        );

        console.log(data);

        navigate("/login");
      } catch (e: any) {
        setError(e.message + ". Please try again.");
      }
    })();
  };

  return (
    <div className="c-verify-otp">
      <div className="c-verify-otp__Verify">
        <div className="c-verify-otp__Verify-label">
          Registration Sucessfull
        </div>

        {error && (
          <>
            <br />
            <Alert message={error} type="error" />
            <br />
          </>
        )}

        <div className="c-verify-otp__Verify-label-message">
          Thanks for registering an account!
        </div>
        <label
          className="c-verify-otp__Verify-label-message"
          htmlFor="otp-input"
        >
          Please check your email for the OTP code and paste it here. If you
          didnt got it, please be sure you checked all the folders such as spam,
          junk, etc.
        </label>
        <div className="c-verify-otp__Verify-form">
          {hadEmptyEmail && (
            <Input
              value={form.email}
              onChange={(e) => {
                authStore.setForm("email", e.target.value);
              }}
              id="email"
              className="c-verify-otp__Verify-label-field"
              placeholder="
                Email address"
              suffix={<UserOutlined />}
            />
          )}
          <Input
            value={form.otp}
            onChange={(e) => {
              authStore.setForm("otp", e.target.value);
            }}
            id="otp-input"
            className="c-verify-otp__Verify-label-field"
            placeholder="Enter OTP code"
            inputMode="numeric"
          />
          <Button
            className="c-verify-otp__Verify-label-button"
            type="primary"
            onClick={nextStep}
          >
            Submit OTP
          </Button>
        </div>
      </div>
    </div>
  );
});
